<script setup>
useHead({ bodyAttrs: { id: 'public' } })
</script>

<template>
  <div class="min-w-screen min-h-screen">
    <slot></slot>

    <default-ui-loading />
  </div>
</template>